import * as Search from '@elevio/kb-kit/lib/components/search';
import { useLanguages, useTranslation } from '@elevio/kb-kit/lib/hooks';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { bannerUrl, brandShowcaseUrl } from '../utils/constants';
import LanguagePicker from './LanguagePicker';
import LoginLogout from './LoginLogout';
import { Logo } from './Logo';
const backgroundImage = `url(${bannerUrl})`;
function Header({ children, headerContainerClass, hideSearch, breadcrumbs, }) {
    const [displaySearchBar, setDisplaySearchBar] = useState(false);
    const { supportedLanguages, selectedLanguage, setCurrentLanguageId } = useLanguages();
    useEffect(() => {
        const googleTranslateInitScript = document.createElement('script');
        if (isMobile()) {
            googleTranslateInitScript.src =
                '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
            googleTranslateInitScript.async = true;
            document.body.appendChild(googleTranslateInitScript);
            const googleTranslateScriptMobile = document.createElement('script');
            googleTranslateScriptMobile.innerHTML = ` function googleTranslateElementInit() {
        new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element_mobile');
       } `;
            document.body.appendChild(googleTranslateScriptMobile);
            googleTranslateInitScript.async = true;
        }
        else {
            googleTranslateInitScript.src =
                '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
            googleTranslateInitScript.async = true;
            const googleTranslateScript = document.createElement('script');
            googleTranslateScript.innerHTML = ` function googleTranslateElementInit() {
      new google.translate.TranslateElement({
        pageLanguage: 'en',
        layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
        autoDisplay: false
      }, 'google_translate_element'); } `;
            document.body.appendChild(googleTranslateScript);
        }
        document.body.appendChild(googleTranslateInitScript);
    }, []);
    const isTablet = () => {
        return navigator.userAgent.match(/iPad/i) != null;
    };
    const isMobile = () => {
        return (navigator.userAgent.match(/Android/i) != null ||
            navigator.userAgent.match(/iPhone/i) != null);
    };
    const isDesktop = () => window.screen.width > 1024;
    const isIE = () => {
        return false || !!document.documentMode;
    };
    const { t: translate } = useTranslation();
    return (<div className="wrapper">
      <MetaTags>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no"/>
      </MetaTags>
      <header className={cx('head-container', headerContainerClass)} data-testid="header">
        <div className="header-wrapper">
          <nav className="nav" aria-label="navigation">
            <div className="nav-wrapper">
              <div className="nav-details ">
                <Logo className="extra-space"/>
                <img className="ask-arvada-logo separator" src={brandShowcaseUrl}/>
              </div>
              <div className="nav-desktop-menu">
                <div id="google_translate_element"></div>
                <LoginLogout className="nav-desktop-menu-link"/>
                {supportedLanguages.length > 1 && (<LanguagePicker selectedLanguageId={selectedLanguage.id} setCurrentLanguageId={setCurrentLanguageId} supportedLanguages={supportedLanguages}/>)}
                <Search.Input placeholder={translate('Search Ventura Connects')} data-testid="search-input" className="hero-search search-bar separator"/>
              </div>
            </div>
          </nav>
          <div className="mobile-menu">
            <div className="mobile-menu-wrapper">
              <LoginLogout className="mobile-menu-link"/>
              {supportedLanguages.length > 1 && (<LanguagePicker selectedLanguageId={selectedLanguage.id} setCurrentLanguageId={setCurrentLanguageId} supportedLanguages={supportedLanguages}/>)}
              <Search.Input placeholder={translate('Search Ventura Connects')} data-testid="search-input" className="hero-search search-bar"/>
            </div>
          </div>
          {displaySearchBar && !hideSearch && (<div className="mobile-search">
              <div className="mobile-search-wrapper">
                <Search.Input className="mobile-search-input"/>
              </div>
            </div>)}
          {children && (<div className="hero" style={{ backgroundImage }}>
              <div className="hero-wrapper">{children}</div>
            </div>)}
          {breadcrumbs}
        </div>
      </header>
    </div>);
}
export default Header;
