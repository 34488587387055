import cx from 'classnames';
const HomeArticles = ({ containerClass, articles }) => {
    return (<div className={cx(containerClass)}>
      {articles.map(({ article }) => (<article className="home-article" id={article.id}>
          <h1 className="home-article-title">{article.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: article.body }} className="home-article-info"/>
        </article>))}
    </div>);
};
export default HomeArticles;
